<template>
  <div>
    Closing session...
  </div>
</template>
<script>
export default {
  name: 'Logout',

  data() {
    return {

    };
  },
  beforeCreate() {
    this.$http.post('logout', {}, { headers: { Authorization: `Bearer ${this.$session.get('jwt')}` } }).then((response) => {
      if (response.status === 200) {
        this.$session.destroy();
        this.$router.push({ name: 'login' });
      }
    }, (error) => {
      console.log('error', error);
    });
  },
  mounted() {

  },

  methods: {

  },
};
</script>
